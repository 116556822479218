import {
  entries,
  flow,
  get,
  keys,
  makeAutoObservable,
  reaction,
  remove,
  set,
  toJS,
  when,
} from "mobx";
import {
  clearPersistedStore,
  isHydrated,
  makePersistable,
} from "mobx-persist-store";
import {
  consigliereClient,
  TApiResponse,
  TBalanceResponse,
  TBridgeConfig,
} from "src/clients";
import { isProduction, walletService } from "src/services";
import { Address, Mnemonic, TokenScheme, Wallet } from "dxs-stas-sdk";

import { RootStore } from "./rootStore";
import {
  AssetBsv,
  AssetBtc,
  AssetDai,
  AssetEth,
  AssetUsdc,
  AssetUsdt,
  AssetUsdxs,
  CryptoAssets,
  EvmNetworks,
  NetworkAny,
  NetworkBsv,
  NetworkEthereum,
  NetworkPolygon,
  NetworkTon,
  NetworkTron,
  TAllNetworks,
  TCryptoAsset,
  TEvmNetwork,
  custodialWalletSetting,
  dxsAccessSetting,
  pkSetting,
  secretSetting,
} from "src/types.enums";
import { CryptoAssetKey } from "src/models";
import {
  TBountyAccount,
  TBsvAccount,
  TEvmAccount,
  TClientSettings,
  TWallet,
  TClient,
} from "src/types";
import { BsvTokenId } from "src/types.enums";

export const mainWalletPath = "m/44'/236'/0'/0/0";
export const fudingWalletPath = "m/44'/236'/0'/0/1";

export const Orders: { [network: string]: { [asset: string]: number } } = {
  [NetworkBsv]: { [AssetBsv]: 0 },
  [NetworkAny]: { [AssetUsdxs]: 1000 },
  [NetworkEthereum]: {
    [AssetUsdt]: 3,
    [AssetUsdc]: 6,
    [AssetDai]: 7,
    [AssetBtc]: 4,
    [AssetEth]: 5,
  },
  [NetworkPolygon]: { [AssetUsdt]: 2 },
  [NetworkTron]: { [AssetUsdt]: 1 },
  [NetworkTon]: { [AssetUsdt]: 1 },
};

export type TCryptoInfo = {
  tokenScheme: TokenScheme;
  cryptoAssetKey: CryptoAssetKey;
  order: number;
};

export class WalletStore {
  _encoded?: string;
  _secret?: string;

  isReady: boolean = false;
  walletLoaded: boolean = false;
  tokenSchemes: {
    [tokenId: string]: TCryptoInfo;
  } = {};

  client?: TClient;
  bsvRate: number = 1;
  btcRate: number = 1;
  ethRate: number = 1;
  trxRate: number = 1;

  bountyTokenId!: string;
  boutyAccount?: TBountyAccount;
  bsvAccount?: TBsvAccount;
  evmAccounts: { [tokenId: string]: TEvmAccount } = {};
  byCryptoAsset: { [cryptoAsset: string]: string } = {};
  byTokenId: { [tokenId: string]: CryptoAssetKey } = {};
  settings: TClientSettings = {};

  bountyBalance: number = 0;
  tokenBalances: { [tokenId: string]: number } = {
    [BsvTokenId]: 0,
  };
  balancesChanged = 0;

  constructor(private rootStore: RootStore) {
    makeAutoObservable(this, {}, { autoBind: true });

    makePersistable(this, {
      storage: window.localStorage,
      stringify: true,
      name: "WalletStore",
      properties: [
        "_encoded",
        "client",
        "bsvAccount",
        "evmAccounts",
        "settings",
        "boutyAccount",
        "bountyBalance",
        "tokenBalances",
        "tokenSchemes",
      ],
    });

    reaction(() => this.rootStore.userStore.hasUser, this._onHasUserChanged);
  }

  get hasPk() {
    return Boolean(this._encoded);
  }

  get hasBsvAccount() {
    return Boolean(this.bsvAccount);
  }

  get BsvAccount() {
    return this.bsvAccount!;
  }

  get tokenIds() {
    return keys(this.tokenSchemes).map((x) => x.toString());
  }

  get secret() {
    return this._secret!;
  }
  get dxsAuthorized() {
    return Boolean(toJS(get(this.settings, dxsAccessSetting)));
  }

  getIsCustodial() {
    return Boolean(toJS(get(this.settings, custodialWalletSetting)));
  }

  get isEthereumAccount() {
    return this.client?.identityProvider !== "Identity";
  }

  get totalUsdBalance() {
    return this.tokensWithBalance.reduce((a, x) => a + x.balanceUsd, 0);
  }

  get tokenWithMaxBalance() {
    const { toAmount, tokenIds, tokenBalances, getInUsd } = this;
    let maxUsdBalance = 0;
    let tokenId = BsvTokenId;
    let balance = 0;

    tokenIds.forEach((tId) => {
      const tokenBalance = toAmount(tId, get(tokenBalances, tId));
      const tokenBalanceInUsd = getInUsd(tId, tokenBalance);

      if (tokenBalanceInUsd > maxUsdBalance) {
        maxUsdBalance = tokenBalanceInUsd;
        balance = tokenBalance;
        tokenId = tId;
      }
    });

    if (balance === 0) {
      const usdtMaticKey = new CryptoAssetKey("Polygon", "Usdt");

      return {
        tokenId: get(this.byCryptoAsset, usdtMaticKey.getKey()),
        balance,
      };
    }

    return { tokenId, balance };
  }

  get tokensWithBalance() {
    return entries(this.tokenBalances)
      .map(([tokenId, balance]) => ({
        tokenId,
        balance: this.toAmount(tokenId, balance),
        balanceUsd: this.getInUsd(tokenId, this.toAmount(tokenId, balance)),
        ...this.byTokenId[tokenId],
        schema: this.tokenSchemes[tokenId],
        order: this.tokenSchemes[tokenId].order,
      }))
      .filter((x) => x.balance > 0)
      .sort((x, y) => y.balanceUsd - x.balanceUsd);
  }

  get tokensZeroBalances() {
    return entries(this.tokenBalances)
      .map(([tokenId, balance]) => ({
        tokenId,
        balance: this.toAmount(tokenId, balance),
        balanceUsd: 0,
        ...this.byTokenId[tokenId],
        schema: this.tokenSchemes[tokenId],
        order: this.tokenSchemes[tokenId].order,
      }))
      .filter((x) => x.balance === 0)
      .sort((x, y) => x.order - y.order);
  }

  getIsUsdToken = (tokenId: string) => {
    if (tokenId === BsvTokenId) return false;

    const schema = this.tokenSchemes[tokenId];

    if (schema.cryptoAssetKey.Asset === "Btc") return false;
    if (schema.cryptoAssetKey.Asset === "Eth") return false;

    return true;
  };

  getInUsd = (tokenId: string, amount: number) => {
    if (tokenId === BsvTokenId) return (amount *= this.bsvRate);

    const schema = this.tokenSchemes[tokenId];

    if (schema.cryptoAssetKey.Asset === "Btc") amount *= this.btcRate;
    if (schema.cryptoAssetKey.Asset === "Eth") amount *= this.ethRate;

    return amount;
  };

  getEvmAccount = (tokenId: string): TEvmAccount =>
    get(this.evmAccounts, tokenId);

  getBalance = (tokenId: string) => {
    const satoshis = get(this.tokenBalances, tokenId) || 0;

    return this.toAmount(tokenId, satoshis);
  };

  getCryptoAssetKey = (tokenId: string) => {
    const { tokenSchemes } = this;
    const { cryptoAssetKey }: TCryptoInfo = get(tokenSchemes, tokenId);

    return cryptoAssetKey;
  };

  getTokenId = (evmNetwork: TAllNetworks, cryptoAsset: TCryptoAsset) => {
    const key = new CryptoAssetKey(evmNetwork, cryptoAsset);
    const tokenId: string = get(this.byCryptoAsset, key.getKey());
    const max = this.rootStore.bridgeStore.maxAvailable;

    let withdrawVia = {
      tokenId,
      evmNetwork,
      cryptoAsset,
    };

    if (cryptoAsset === "Usdxs" && max.tokenId) {
      let wT = max.tokenId;
      const {
        cryptoAssetKey: { Network, Asset },
      } = this.tokenSchemes[wT];

      withdrawVia = { tokenId: wT, evmNetwork: Network, cryptoAsset: Asset };
    }

    return { tokenId, withdrawVia };
  };

  init = flow(function* (this: WalletStore) {
    yield when(() => isHydrated(this));
    yield when(() => this.rootStore.userStore.isReady);

    const { payload: bridgeConfig }: TApiResponse<TBridgeConfig> =
      yield consigliereClient.getBridgeConfig();

    this.bountyTokenId = bridgeConfig!.bountyTokenId;

    const allAssets = bridgeConfig!.assets;

    const bsvCryptoAsset = new CryptoAssetKey("Bsv", "Bsv");

    const unordered: {
      tokenId: string;
      asset: TCryptoInfo;
    }[] = [
      {
        tokenId: BsvTokenId,
        asset: {
          tokenScheme: new TokenScheme("BSV", BsvTokenId, "BSV", 100_000_000),
          cryptoAssetKey: bsvCryptoAsset,
          order: Orders[NetworkBsv][AssetBsv],
        },
      },
    ];

    set(this.byCryptoAsset, bsvCryptoAsset.getKey(), BsvTokenId);
    set(this.byTokenId, BsvTokenId, bsvCryptoAsset);

    Object.keys(allAssets).forEach((network: string) => {
      const evmNetwork = EvmNetworks.check(network);
      const networkAssets = allAssets[network];

      Object.keys(networkAssets).forEach((asset: string) => {
        const cryptoAsset = CryptoAssets.check(asset);
        const assetConfig = networkAssets[asset];

        const tokenScheme = new TokenScheme(
          assetConfig!.name,
          assetConfig!.tokenId,
          assetConfig!.symbol,
          assetConfig!.satoshisPerToken
        );

        const cryptoAssetKey = new CryptoAssetKey(evmNetwork, cryptoAsset);

        unordered.push({
          tokenId: assetConfig!.tokenId,
          asset: {
            tokenScheme,
            cryptoAssetKey,
            order: Orders[evmNetwork][cryptoAsset],
          },
        });

        set(this.byCryptoAsset, cryptoAssetKey.getKey(), assetConfig!.tokenId);
        set(this.byTokenId, assetConfig!.tokenId, cryptoAssetKey);
      });
    });

    unordered
      .sort((a, b) => a.asset.order - b.asset.order)
      .forEach(({ tokenId, asset }) => set(this.tokenSchemes, tokenId, asset));

    this.isReady = true;
  });

  load = this.rootStore.blockingCall(
    flow(function* (root: RootStore) {
      yield root.walletStore.loadWallet();
      yield root.walletStore.ensureStoredMnemonicIsCorrect();
    })
  );

  loadWallet = this.rootStore.singleCall(
    "loadWallet",
    flow(function* (rootStore: RootStore) {
      const {
        userStore: { AccessToken },
        walletStore: $this,
      } = rootStore;

      $this.walletLoaded = false;

      const { payload: wallet }: TApiResponse<TWallet> =
        yield consigliereClient.getWallet(AccessToken);

      if (wallet) {
        $this.client = wallet.client;
        $this.bsvAccount = wallet.bsvAccount;
        $this.boutyAccount = wallet.bountyAccount;

        const {
          [secretSetting]: secret,
          [pkSetting]: pk,
          ...settings
        } = wallet.settings;

        $this._secret = secret;
        if (pk) $this._encoded = pk;
        $this.settings = settings;

        const noBsvTokenIds = $this.tokenIds.filter((x) => x !== BsvTokenId);

        noBsvTokenIds.forEach((tokenId) => remove($this.evmAccounts, tokenId));

        wallet.evmAccounts.forEach((x) => {
          set($this.evmAccounts, x.tokenId, x);
        });
        $this.bsvRate = wallet.bsvRate;
        $this.btcRate = wallet.btcRate;
        $this.ethRate = wallet.ethRate;
        $this.trxRate = wallet.trxRate;
        $this.walletLoaded = true;
      }
    })
  );

  ensureStoredMnemonicIsCorrect = flow(function* (this: WalletStore) {
    if (!this._encoded) return;

    let valid = true;
    try {
      const mnemonic = yield this.getMnemonicOrThrow();

      if (this.hasBsvAccount) {
        const { main }: { main: string; funding: string } =
          yield this.getBsvAddresses(mnemonic);

        if (main !== this.BsvAccount.address) {
          if (!isProduction) {
            console.error("Stored mnemonic doesn't match with bsvAccount");
          }

          valid = false;
        }
      }
    } catch (error) {
      if (!isProduction) console.error(error);

      valid = false;
    }

    if (!valid) {
      this._encoded = undefined;
    }
  });

  getMnemonicOrThrow = flow<Mnemonic, []>(function* (this: WalletStore) {
    try {
      return yield walletService.decodeMnemonic(this.secret, this._encoded!);
    } catch (error) {
      this._encoded = undefined;
      throw Error("Ensure mnemonic is incorrect");
    }
  });

  setPk = this.rootStore.singleCall<boolean, [Mnemonic]>(
    "setPk",
    flow(function* (rootStore: RootStore, mnemonic: Mnemonic) {
      const {
        mnemonicStore,
        userStore: { AccessToken },
        walletStore: $this,
      } = rootStore;

      if (!$this.hasBsvAccount) {
        try {
          const { main, funding }: { main: string; funding: string } =
            yield $this.getBsvAddresses(mnemonic);

          const { payload: bsvAccount }: TApiResponse<TBsvAccount> =
            yield consigliereClient.createBsvAccount(
              AccessToken,
              main,
              funding
            );

          if (!bsvAccount) return false;

          $this.bsvAccount = bsvAccount;
        } catch {
          return false;
        }
      }

      yield mnemonicStore.encodeMnemonicAndStore(mnemonic);

      return true;
    })
  );

  createPkAndSave = this.rootStore.blockingCall(
    this.rootStore.singleCall(
      "createPkAndSave",
      flow(function* (rootStore: RootStore) {
        const {
          userStore: { AccessToken },
        } = rootStore;

        const { payload: secret }: TApiResponse<string | null> =
          yield consigliereClient.getSetting(AccessToken, "Secret");

        if (!secret) throw Error("Server error");

        const mnemonic = Mnemonic.generate();
        const encodedMnemonic: string = yield walletService.encodeMnemonic(
          secret,
          mnemonic
        );

        const { error }: TApiResponse<null> =
          yield consigliereClient.updateSetting(
            AccessToken,
            pkSetting,
            encodedMnemonic
          );

        if (error) throw error;

        yield rootStore.walletStore.setPk(mnemonic);
      })
    )
  );

  createEvmAccount = this.rootStore.singleCall(
    "createEvmAccount",
    flow(function* (
      rootStore: RootStore,
      withdrawAddress: string,
      evmNetwork: TEvmNetwork,
      cryptoAsset: TCryptoAsset
    ) {
      const {
        userStore: { AccessToken },
        walletStore: $this,
        notificationStore: { addNotification },
      } = rootStore;

      try {
        const { payload, error }: TApiResponse<TEvmAccount> =
          yield consigliereClient.createEvmAccount(AccessToken, {
            evmNetwork,
            cryptoAsset,
            withdrawAddress,
          });

        if (!payload || error) {
          addNotification("Ask support");
          return;
        }

        set($this.evmAccounts, payload.tokenId, payload);
        set($this.tokenBalances, payload.tokenId, 0);

        return true;
      } catch {
        return false;
      }
    })
  );

  grantDxsAccess = this.rootStore.blockingCall(
    flow(function* (rootStore: RootStore) {
      const $this = rootStore.walletStore;
      const { AccessToken } = rootStore.userStore;
      const { error, payload }: TApiResponse<string> =
        yield consigliereClient.grantAccess(AccessToken);

      if (error) throw error;
      if (payload) $this.settings[dxsAccessSetting] = payload;
    })
  );

  getRedeemAddress = (tokenId: string) => Address.fromHash160Hex(tokenId);

  getTokenScheme = (tokenId: string) => {
    if (tokenId === this.bountyTokenId) {
      return {
        tokenScheme: new TokenScheme(
          "Bounty",
          this.bountyTokenId,
          "Bounty",
          100
        ),
        cryptoAssetKey: new CryptoAssetKey("Any", "Usdxs"),
        order: 1000,
      };
    }

    const result: TCryptoInfo = get(this.tokenSchemes, tokenId);

    if (!result) {
      throw Error(`Token is not defined: ${tokenId}`);
    }

    return result;
  };

  toAmount = (tokenId: string, satoshis: number) => {
    const { tokenScheme } = this.getTokenScheme(tokenId);

    return satoshis / tokenScheme.SatoshisPerToken;
  };

  createBsvWallets = flow<Wallet[], [Mnemonic]>(function* (
    this: WalletStore,
    mnemonic: Mnemonic
  ) {
    const [main, funding]: Wallet[] = yield walletService.getWallets(mnemonic, [
      mainWalletPath,
      fudingWalletPath,
    ]);

    return [main, funding];
  });

  getBsvAddresses = flow(function* (this: WalletStore, mnemonic: Mnemonic) {
    const [main, funding] = yield this.createBsvWallets(mnemonic);

    return { main: main.Address.Value, funding: funding.Address.Value };
  });

  refreshBalances = this.rootStore.singleCall(
    "refreshBalances",
    flow(function* (rootStore: RootStore) {
      const {
        userStore: { hasUser, AccessToken },
        walletStore: $this,
        walletStore: {
          bountyTokenId,
          tokenIds,
          hasBsvAccount,
          boutyAccount,
          BsvAccount,
        },
      } = rootStore;

      if (!hasUser || !hasBsvAccount) return;

      const addresses = [BsvAccount.address];

      let noBsvTokenIds = tokenIds.filter((x) => x !== BsvTokenId);

      if (boutyAccount) {
        addresses.push(boutyAccount.address);
        noBsvTokenIds = [...noBsvTokenIds, bountyTokenId];
      }

      const balances: TApiResponse<TBalanceResponse> =
        yield consigliereClient.getBalances(
          AccessToken,
          addresses,
          noBsvTokenIds
        );

      if (balances.payload) {
        const tokenIdsWithBalance = new Set<string>();

        for (const balance of balances.payload) {
          if (balance.tokenId) {
            if (balance.tokenId === $this.bountyTokenId) {
              if (balance.address === boutyAccount?.address) {
                $this.bountyBalance = balance.satoshis;

                tokenIdsWithBalance.add(balance.tokenId);
              }
            } else {
              if (balance.address === BsvAccount.address) {
                set($this.tokenBalances, balance.tokenId, balance.satoshis);

                tokenIdsWithBalance.add(balance.tokenId);
              }
            }
          } else {
            if (balance.address === BsvAccount.address) {
              set($this.tokenBalances, BsvTokenId, balance.satoshis);
            }
          }
        }

        noBsvTokenIds
          .filter((x) => !tokenIdsWithBalance.has(x))
          .forEach((tokenId) => {
            if (tokenId === bountyTokenId) {
              $this.bountyBalance = 0;
            } else {
              set($this.tokenBalances, tokenId, 0);
            }
          });

        $this.balancesChanged = $this.balancesChanged * -1;
      }
    })
  );

  clear = flow(function* (this: WalletStore) {
    this._encoded = undefined;
    this.bsvAccount = undefined;
    this.evmAccounts = {};
    this.boutyAccount = undefined;
    this.bountyBalance = 0;
    this.tokenBalances = {
      [BsvTokenId]: 0,
    };
    this.client = undefined;
    this.settings = {};

    yield clearPersistedStore(this);
  });

  _onHasUserChanged = flow(function* (
    this: WalletStore,
    hasUser: boolean,
    prevValue: boolean
  ) {
    if (!hasUser && prevValue) yield this.clear();
    if (hasUser) yield this.load();
  });
}
