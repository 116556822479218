import { isProduction } from "src/services";

let enableConsoleDebug = !isProduction;
let index = 0;

const getDefaultTags = () => ["#debug", "#FIORIN", `#index-${index++}`];

const logDebug = (...data: any[]) => {
  if (enableConsoleDebug) {
    console.debug(...[...(data ?? []), ...getDefaultTags()]);
  }
};

const logError = (...data: any[]) => {
  console.error(...[...(data ?? []), "#error", ...getDefaultTags()]);
};

export const logService = {
  logDebug,
  logError,
};
